.maintaining_body_con {
  margin-left: 0 !important;
  max-width: 100% !important;
}
.maintaining_con {
  position: absolute;
  // width: 100%;
  left: 0;
  right: 0;
  height: 100%;
  background-image: url("../assets/images/noticebg_pic.png") !important;
  background-position: center -80px;
  background-size: 100% auto;
  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(226, 188, 80, 0.9);
  }
  .maintain_page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
  }
  .maintain_fl {
    margin-right: 50px;
  }
  .maintain_fr {
    width: 412px;
    h6 {
      font-size: 50px;
      margin: 0;
      color: #030303;
      line-height: 60px;
      margin-bottom: 26px;
    }
    p {
      font-size: 18px;
      color: #030303;
      line-height: 30px;
    }
  }
  .maintain_logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 114px;
    }
    &::before {
      width: 98px;
      height: 1px;
      background-color: #030303;
      content: "";
      display: block;
    }
  }
}

@media only screen and (max-width: 750px) {
  .maintaining_con {
    background-image: url("../assets/images/noticebg_mobile_pic.png") !important;
    background-position: center 0;
    background-size: 124% auto;
  }
}

@media only screen and (max-width: 970px) {
  //维护
  .maintaining_con {
    .maintain_page {
      display: block;
      text-align: center;
    }
    .maintain_fl {
      margin-right: 0;
      margin-bottom: 1.3333rem;
      img {
        width: 14.6667rem;
      }
    }
    .maintain_fr {
      width: 100%;

      h6 {
        font-size: 2.1667rem;
        margin: 0;
        line-height: 2.5rem;
        margin-bottom: 1.3333rem;
      }
      p {
        font-size: 1.1667rem;
        line-height: 1.6667rem;
        margin-bottom: 2.3333rem;
      }
    }
    .maintain_logo {
      display: block;
      img {
        width: 8.8333rem;
      }
      &::before {
        display: none;
      }
    }
  }
}
